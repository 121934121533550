<template>
    <div>

        <!-- Header-Image Section -->
        <section class="header-image">

            <div class="container text-center">
                <h1 class="text-white">{{ blog.title }}</h1>
            </div>

        </section>

        <!-- Actuality Section -->
        <section class="blog bg-light-gray">
            <div class="container">
                <h2 class="text-center actuality"></h2>
                <div class="row">
                    <div class="col-md-3">
                    </div>
                    <div class="col-md-6">
                        <div v-if="!blog.id" class="shimmer-line"
                            style="animation: shimmer 2.5s linear infinite;height: 400px;width: 100%;">
                        </div>
                        <div v-else class="card">
                            <img v-if="blog.image == undefined || blog.image == null || blog.image == ''"
                                :src="require('@/assets/images/website/400x200.png')" class="card-img-top"
                                alt="Blog Image">
                            <img v-else :src="$serverpath + blog.image" class="card-img-top" alt="Blog Image"
                                height="100%" width="100%">
                            <div class="card-body">
                                <h5 class="card-title">{{ blog.title }}</h5>
                                <p class="card-text">{{ blog.description }}</p>
                            </div>
                            <b-card-text v-if="blog.details">
                                <quill-editor disabled :options='editorOption' v-model='blog.details'></quill-editor>
                            </b-card-text>
                        </div>
                    </div>
                    <div class="col-md-3">
                    </div>
                </div>
            </div>
        </section>

        <hr>

    </div>

</template>

<script>

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
    quillEditor
} from 'vue-quill-editor';

export default {
    metaInfo() {
        return {
            title: 'PharmaConnect | ' + this.blog.title,
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: this.blog.description
                },
                {
                    name: 'keywords',
                    content: this.blog.description
                },
                {
                    name: 'author',
                    content: 'DIGITHINK CONSULTING'
                },
                {
                    property: 'og:title',
                    content: 'PharmaConnect | ' + this.blog.title
                },
                {
                    property: 'og:description',
                    content: this.blog.description
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/news/" + this.blog.id,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: 'PharmaConnect | ' + this.blog.title
                },
                {
                    name: "twitter:description",
                    content: this.blog.description
                },
                {
                    name: "twitter:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/news/" + this.blog.id,
                },
            ],
            script: [
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "PharmaConnect | " + this.blog.title,
                        "description": this.blog.description,
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        }
                    },
                },
            ],
        };
    },
    components: {
        quillEditor
    },
    data() {
        return {
            blog: {},
            editorOption: {
                theme: 'bubble',
                readOnly: true
            },
        };
    },
    methods: {
        async loadBlog() {
            try {
                let response = await this.$http.get("blog/" + this.$route.params.id);
                this.blog = response.data;
            } catch (error) {
                console.log(error)
            }
        }
    },
    created() {
        this.loadBlog();
    }
};
</script>

<style scoped>
.header-image {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('~@/assets/images/website/page-header-bg.jpg') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 50px 0;
}

.actuality {
    padding: 10px;
}

.shimmer-line {
    margin-left: 10px;
    height: 200px;
    background: linear-gradient(to right, #f3f3f3 8%, #e5e5e5 18%, #f3f3f3 33%);
    background-size: 800px 104px;
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}
</style>